<template>
	<div class="row">
		<div class="columns column12">
			<div class="header-bar">
				<div class="flex-col">
					<div class="menu-button" @click="$emit('toggle-nav')">
						<div class="menu-icon">
							<font-awesome-icon :icon="`fal fa-bars`" size="1x" />
						</div>
						<span>{{ $t('buttonMenu') }}</span>
					</div>
					<divider />
					<lang-nav />
					<a class="vacancy-link" :href="config.public.siteUrlGroup + vacancyPage.filename" target="_blank">
						<span>{{ $t('joinOurTeam') }}</span>
					</a>
				</div>
				<div class="flex-col">
					<nuxt-link
						class="logo"
						:to="{ path: localePath('/'), hash: route.name.startsWith('index') ? '#top' : '' }"
					/>
				</div>
				<div class="flex-col">
					<div class="cta-buttons">
						<book-button class="button book-now button-cta reserve-now">
							<span>{{ $t('bookARoom') }}</span>
						</book-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
const { groupsPages, fetchGroupsPages } = await useGroupsPages();
const { $i18n } = useNuxtApp();
const route = useRoute();

const config = useRuntimeConfig();

defineEmits(['toggle-nav']);

await fetchGroupsPages();

$i18n.onBeforeLanguageSwitch(async () => {
	await fetchGroupsPages();
});

const vacancyPage = computed(() => groupsPages.value.find((page) => page.slug === 'vacancies-index'));
</script>

<style lang="scss" scoped>
.vacancy-link {
	text-transform: uppercase;
	font-weight: 900;
	padding: 5px 0;
	text-decoration: none;
	color: var(--header-color);
}

.logo {
	padding: 0;
	text-decoration: none;
	margin: 0 auto;
	width: 130px;
	height: 71px;
	background: url('~/assets/images/logo-hotel-jlno76-full-dark.png') no-repeat center center;
	background-size: 130px;
}

.menu-button {
	cursor: pointer;
	text-transform: uppercase;
	padding: 10px 15px 10px 0;
	margin: 0 15px 0 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	font-weight: 900;
	position: relative;

	.menu-icon {
		width: 42px;
		height: 38px;
		text-align: center;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: center;
		margin: 0 15px 0 0;
		font-size: 28px;
	}

	&::after {
		display: block;
		content: '';
		width: 100%;
		height: 1px;
		background: #3c3834;
		opacity: 0;
		transition: all 0.3s ease-in-out;
		position: absolute;
		bottom: 0;
		left: 0;
	}

	&.active,
	&:hover {
		color: var(--body-color);

		&::after {
			opacity: 1;
		}
	}
}

.header-bar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 3px 0;
	z-index: 9;
	position: relative;

	.flex-col {
		width: 30%;
		display: flex;
		align-items: center;
		justify-content: center;

		&:first-child {
			width: 40%;
			justify-content: flex-start;
			text-align: left;
		}

		&:last-child {
			width: 40%;
			justify-content: flex-end;
			text-align: right;
		}
	}

	.cta-buttons {
		display: flex;
		align-items: center;
		gap: 10px;

		.button {
			margin: 0;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}

@media (max-width: 1280px) {
	.logo {
		width: 100px;
		height: 55px;
		background: url('~/assets/images/logo-hotel-jlno76-medium-dark.png') no-repeat center center;
		background-size: 100px;
	}

	.header-bar a {
		font-size: 14px;
	}

	.header-bar .cta-buttons .button {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media (max-width: 1200px) {
	.header-bar .buttons .button,
	.vacancy-link {
		display: none;

		span {
			display: none;
		}
	}

	.header-bar {
		.flex-col {
			width: 100%;
			justify-content: center;
			align-items: center;
			text-align: left;

			&:first-child,
			&:last-child {
				display: none;
			}
		}

		.logo {
			margin: 0;
		}
	}
}

@media (max-width: 580px) {
	.header-bar .buttons span {
		display: none;
	}
}
</style>
